
















































import Vue from 'vue';
import moment from 'moment';
import * as api from '@/api/couponDistribute';
export default Vue.extend({
  name: 'distributeDetail',
  props: [],
  data() {
    return {
      showModal: false,
      basicData: {},
      formLoading: false, //表单加载状态
    };
  },
  methods: {
    moment,
    show(record) {
      this.showModal = true;
      this.basicData = {};
      this.getDistributeDetail(record.sequenceNbr);
    },
    // 查询详情数据
    getDistributeDetail(id) {
      this.formLoading = true;
      api
        .getDistributeDetail(id)
        .then((res) => {
          if (res.status === 200) {
            this.basicData = res.result;
          }
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
  },
});
